const baseUrl = process.env.NODE_ENV == 'development' ? 'https://api.yyservice.nbyuyuan.net/api/' : 'https://api.yyservice.nbyuyuan.net/api/';
import axios from 'axios'
import qs from 'qs'
axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common['request-header'] = 'YuYuanApi';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
export const utils = {
    get: (url, data) => {
        var commonAccount = JSON.parse(localStorage.getItem('commonAccount')) || {}
        data = data ? data : ''
        return new Promise ((resolve, reject) => {
            data = Object.assign(data, commonAccount)
            axios.get(url, {
                params : data
            }).then(res => {
                var ret = res.data
                console.log(ret)
                if (ret.code == 1) {
                    resolve(ret.data)
                } else {
                    console.log(ret)
                    reject()
                }
            })
        })
    },
    post: (url, data) => {
        return new Promise ((resolve, reject) => {
            axios.post(url ,qs.stringify(data)).then(res => {
                var ret = res.data
                if (ret.code == 1) {
                    resolve(ret.data)
                } else {
                    console.log('%c接口: ' + url + ":" + ret.msg + ' && line: ' + ret.line, 'color: red; font-size: 18px')
                    reject(ret.data)
                }
            })
        })
    },
    formatterTimeString: function(fmt, date) {
        var o = {   
            "M+" : date.getMonth()+1,                 //月份   
            "d+" : date.getDate(),                    //日   
            "h+" : date.getHours(),                   //小时   
            "m+" : date.getMinutes(),                 //分   
            "s+" : date.getSeconds(),                 //秒   
            "q+" : Math.floor((date.getMonth()+3)/3), //季度   
            "S"  : date.getMilliseconds()             //毫秒   
          };   
          if(/(y+)/.test(fmt))   
            fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));   
          for(var k in o)   
            if(new RegExp("("+ k +")").test(fmt))   
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));   
          return fmt;
    },
    scrollToMsgBoxBottom: function(tagName) {
        var ele = document.getElementById(tagName);
        ele.scrollTop = ele.scrollHeight;
        console.log(ele.scrollHeight)
    },
    showModalBox(str, fn) {
        var element = document.createElement("div"), body = document.body;
        element.className = 'yyservice-modal'
        element.id = 'yyservice-modal'
        var el = `<div class="yyservice-modal">
            <div class="yyservice-modal-box">
            <div class="yyservice-modal-title">温馨提示<div>
            <div class="yyservice-modal-content">${str}</div>
            <div class="yyservice-modal-tools">
                <span id="yyservice-modal-cancle" class="yyservice-modal-btn yyservice-modal-cancle">取消</span>
                <span id="yyservice-modal-sure" class="yyservice-modal-btn yyservice-modal-sure">确定</span>
            </div>
            </div>`;
        element.innerHTML = el
        body.appendChild(element)
        
        setTimeout(function() {
            var cancleBtn = document.getElementById('yyservice-modal-cancle')
            cancleBtn.addEventListener('click', function() {
                var m = document.getElementById('yyservice-modal')
                m.remove()
            }, false)
    
            var sureBtn = document.getElementById('yyservice-modal-sure')
            sureBtn.addEventListener('click', function() {
                var m = document.getElementById('yyservice-modal')
                m.remove()
                if (fn) {
                    fn()
                }
            }, false)
        }, 10);
        // $(document).on('click', '.yyservice-modal .yyservice-modal-cancle', function() {
        //     $('.yyservice-modal').fadeOut(100)
        // })
        // $(document).on('click', '.yyservice-modal .yyservice-modal-sure', function() {
        //     $('.yyservice-modal').fadeOut(100)
        //     if (fn) {
        //         fn()
        //     }
        // })
    }
}


var emojiBaseUrl = 'https://images.nbyuyuan.com/images/nbyuyuan/emoji/';
export const yyservice_emoji = {
    '[yyservice_gif_01]': emojiBaseUrl + '53c_1.gif?t=001',
    '[yyservice_gif_02]': emojiBaseUrl + '53c_2.gif?t=0012',
    '[yyservice_gif_03]': emojiBaseUrl + '53c_3.gif?t=001',
    '[yyservice_gif_04]': emojiBaseUrl + '53c_4.gif?t=001',
    '[yyservice_gif_05]': emojiBaseUrl + '53c_5.gif?t=001',
    '[yyservice_gif_06]': emojiBaseUrl + '53c_6.gif?t=001',
    '[yyservice_gif_07]': emojiBaseUrl + '53c_7.gif?t=001',
    '[yyservice_gif_08]': emojiBaseUrl + '53c_8.gif?t=001',
    '[yyservice_gif_09]': emojiBaseUrl + '53c_9.gif?t=001',
    '[yyservice_gif_10]': emojiBaseUrl + '53c_10.gif?t=001',
    '[yyservice_gif_11]': emojiBaseUrl + '53c_11.gif?t=001',
    '[yyservice_gif_12]': emojiBaseUrl + '53c_12.gif?t=001',
    '[yyservice_gif_13]': emojiBaseUrl + '53c_13.gif?t=001',
    '[yyservice_gif_14]': emojiBaseUrl + '53c_14.gif?t=001',
    '[yyservice_gif_15]': emojiBaseUrl + '53c_15.gif?t=001',
    '[yyservice_gif_16]': emojiBaseUrl + '53c_16.gif?t=001',
    '[yyservice_gif_17]': emojiBaseUrl + '53c_17.gif?t=001',
    '[yyservice_gif_18]': emojiBaseUrl + '53c_18.gif?t=001',
    '[yyservice_gif_19]': emojiBaseUrl + '53c_19.gif?t=001',
    '[yyservice_gif_20]': emojiBaseUrl + '53c_20.gif?t=001',
    '[yyservice_gif_21]': emojiBaseUrl + '53c_21.gif?t=001',
    '[yyservice_gif_22]': emojiBaseUrl + '53c_22.gif?t=001',
    '[yyservice_gif_23]': emojiBaseUrl + '53c_23.gif?t=001',
    '[yyservice_gif_24]': emojiBaseUrl + '53c_24.gif?t=001',
    '[yyservice_gif_25]': emojiBaseUrl + '53c_25.gif?t=001',
    '[yyservice_gif_26]': emojiBaseUrl + '53c_26.gif?t=001',
    '[yyservice_gif_27]': emojiBaseUrl + '53c_27.gif?t=001',
    '[yyservice_gif_28]': emojiBaseUrl + '53c_28.gif?t=001',
    '[yyservice_gif_29]': emojiBaseUrl + '53c_29.gif?t=001',
    '[yyservice_gif_30]': emojiBaseUrl + '53c_30.gif?t=001',
    '[yyservice_gif_31]': emojiBaseUrl + '53c_31.gif?t=001',
    '[yyservice_gif_32]': emojiBaseUrl + '53c_32.gif?t=001',
    '[yyservice_gif_33]': emojiBaseUrl + '53c_33.gif?t=001',
    '[yyservice_gif_34]': emojiBaseUrl + '53c_34.gif?t=001',
    '[yyservice_gif_35]': emojiBaseUrl + '53c_35.gif?t=001'
}